import { defineStore } from 'pinia';
import { useApi } from 'src/composables/api/useApi';
import { computed, ref } from 'vue';
import { sortBy } from '../util/sortBy';
import { Event } from 'src/types/Event';

export const useEventsStore = defineStore('events', () => {
  const { request } = useApi();

  const events = ref<Event[]>([]);

  const active_event_id = ref<number | null>(null);

  const oldEventsCount = ref(0);

  const newEventsCount = ref(0);

  const getNewEventsCount = computed(
    () => newEventsCount.value - oldEventsCount.value,
  );

  const getEvents = computed(() => {
    return events.value;
  });

  function getEventById(id: number) {
    const cachedEvents = events.value.find(
      (event) => event?.id === id.toString(),
    );
    return cachedEvents;
  }

  function buildParams(from?: string, to?: string) {
    if (from && to) {
      return {
        start: formatTimestampFrom(from),
        end: formatTimestampTo(to),
      };
    } else return {};
  }

  function formatTimestampFrom(timestamp: string) {
    const offset = new Date().getTimezoneOffset(); // offset hours by local timezone
    const date = new Date(timestamp);
    date.setTime(date.getTime() + offset * 1000); // convert local date and time filter to Time in UTC
    return date.toISOString();
  }

  function formatTimestampTo(timestamp: string) {
    const offset = new Date().getTimezoneOffset(); // offset hours by local timezone
    const date = new Date(timestamp);
    date.setTime(date.getTime() + 24 * 60 * 59.7 * 1000 + offset * 1000); // convert local date and time filter to Time in UTC
    return date.toISOString();
  }

  /*
   * Actions
   */
  async function fetchEvents(systemId: string, from?: string, to?: string) {
    events.value = [];
    if (!systemId.length) {
      throw 'No system Id present';
    }
    const config = request({
      url: `/systems/${systemId}/events`,
      method: 'GET',
      params: buildParams(from, to),
      options: {
        timeout: 10000,
      },
      includeInHealthCheck: false
    });
    await config
      .execute()
      .then((response) => {
        if (response) {
          if (!response.data.value.length) {
            response.data.value = [];
          }
          const excludeList = [
            'device_control_intent_imparted',
            'device_control_intent_removed',
            'leading_device_control_intent_updated',
          ];
          const mappedConnectionEventsToSystemEvents = response.data.value
            .map((e: Event) => {
              e.tags = e.tags.map((tag) => {
                if (tag === 'connection') return 'system';
                return tag;
              });
              if (e.type === 'device_added') {
                e.tags = ['system'];
              }
              // if(e.tags.includes('system') && e.tags.includes('device')) {

              // }
              return e;
            })
            .filter((evt: Event) => {
              return !excludeList.includes(evt.type);
            });

          events.value = sortBy(
            mappedConnectionEventsToSystemEvents,
            'timestamp',
          );
          if (!from && !to) {
            // calculate new/old events and display on bell's badge only when data fetches without calendar filter
            oldEventsCount.value = (response.data.value as Array<Event>).length;
          }
        }
      })
      .catch(() => {
        events.value = [];
      });

    return config;
  }

  async function updateEvents(systemId: string, eventData: Event) {
    if (!systemId.length) {
      throw 'No system Id presented';
    }
    events.value = events.value.filter((event) => event?.id == eventData.id);
    events.value.push(eventData);
  }

  function $reset() {
    // This function is used to reset the store when the user logs out
    // TODO: do we need to reset anything for this store?
  }
  return {
    events,
    fetchEvents,
    updateEvents,
    getEvents,
    getEventById,
    getNewEventsCount,
    active_event_id,
    $reset,
  };
});
