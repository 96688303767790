import { computed, onMounted, ref } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';

import { useInstallation } from './useInstallation';

import { Installation, InstallationState } from 'types/Installation';

const installations = ref<Installation[]>([]);
type InstallationManagerOptions = {
  precheck?: {
    errorFallbackRoute?: RouteLocationRaw;
    callbackFunction?: () => void;
  };
};

// The installation manager keeps track of all systems installation
export function useInstallationsManager(options?: InstallationManagerOptions) {
  const router = useRouter();

  onMounted(async () => {
    // If there is no active installation, error out to fallback route or kick to root
    if (
      !getActiveInstallation.value &&
      router.currentRoute.value.name === 'StartSetup'
    ) {
      router.push({ name: 'Root' });
    } else if (!getActiveInstallation.value) {
      if (options?.precheck?.errorFallbackRoute) {
        router.push(options.precheck.errorFallbackRoute);
      }
    } else {
      // If there is an active installation, run the precheck callback if it is present
      if (options?.precheck?.callbackFunction)
        options.precheck.callbackFunction();
    }
  });

  const getActiveInstallation = computed<Installation | undefined>(() => {
    return installations.value.find((installation) => {
      return installation.isActive;
    });
  });

  function deactivateAll() {
    installations.value.forEach((installation) => {
      installation.setActive(false);
    });
  }

  function searchForInstallation(key: string, value: unknown) {
    const foundInstallation = installations.value.find((installation) => {
      const foundValue = installation.findInState(key);
      return foundValue === value;
    });
    return foundInstallation;
  }

  async function setupInstallation(
    state?: InstallationState,
    templatePath = './installation.js',
  ) {
    // Create the instance
    const installation = useInstallation(router);
    // setup our template with an optional system param
    await installation.setup(state, templatePath);
    installations.value.push(installation);
    return installation;
  }

  return {
    getActiveInstallation,
    searchForInstallation,
    setupInstallation,
    deactivateAll,
  };
}
