import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ZeroConfService } from '@luminsmart/capacitor-zeroconf';
import { useZeroConf } from 'src/composables/useZeroConf';
import { Capacitor } from '@capacitor/core';
import { useApi } from 'src/composables/api/useApi';
import { Capabilities, HubInfo } from 'src/types/HubInfo';

export declare type NetworkInterfaceInfo = {
  running: boolean; // Indicates if the interface is active
  mac_addr: string[]; // Array of MAC addresses (usually one or empty for virtual interfaces)
  v4_addrs: string[]; // Array of IPv4 addresses (with optional ports)
  v6_addrs: string[]; // Array of IPv6 addresses
};

export declare type NetworkInterfaces = {
  [interfaceType: string]: NetworkInterfaceInfo; // Maps interface names (e.g., 'lo', 'wlan0') to their details
};
// Hub API spec: https://github.com/luminsmart/hedy/blob/main/openapi.yaml
export const useHubStore = defineStore('hub', () => {
  const { request } = useApi();
  const zeroConf = useZeroConf();

  type Network = { ssid: string };
  const networks = ref<Network[]>([]);

  function $reset() {
    networks.value = [];
  }

  const getHubUrl = (hub: ZeroConfService) => {
    if (!hub) throw 'GetHubUrl() - Hub is undefined';
    // get the hub's domain, protocol and port
    const { txtRecord, name } = hub;
    const protocol = txtRecord?.protocol;
    const port: number | string = hub.port;
    // When we abstract the protocol out form a new Url() it will have a : so we need to get rid of that.
    const wsProtocol = protocol.split(':')[0] === 'https' ? 'wss' : 'ws';
    // Return the dns address
    if (Capacitor.getPlatform() !== 'android')
      return {
        wsUrl: new URL(`${wsProtocol}://${name}.local:${port}`),
        url: `${protocol}://${name}.local:${port}`,
        tunnel: '',
      };

    // Return the Ip address
    const ip = hub.ipv4Addresses[0];
    if (ip) {
      return {
        wsUrl: new URL(`${wsProtocol}://${ip}:${port}`),
        url: `${protocol}://${ip}:${port}`,
        tunnel: '',
      };
    } else throw { message: 'Error: Could not get hub url', service: hub };
  };

  //wifi-failed endpoint that you can implement into this logic and let the user know
  //why the network connection failed
  let timeoutId: string | number | NodeJS.Timeout | undefined;
  const attempts = ref(0);
  //set hedy to 30 seconds before puting it back in setup mode - current at 60 seconds
  const maxAttempts = 14;

  function didHubConnect(
    resolve: (value: unknown) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reject: (reason?: any) => void,
    hub: ZeroConfService,
  ) {
    console.info(
      'Setup - Setup - didHubConnect() checking if the hub can connect...',
    );
    attempts.value++;

    if (attempts.value < maxAttempts) {
      console.info('Setup - didHubConnect() attempt #', attempts);
      timeoutId = setTimeout(() => {
        testForHub(resolve, reject, hub); // Retry connection
      }, 3000);
    } else if (attempts.value >= maxAttempts) {
      console.error('didHubConnect() Max attempts reached!');
      reject('The hub could not connect. Please try again');
    }
  }

  async function testForHub(
    resolve: (value: unknown) => void,
    // TODO NOTE: (Ben,2023-09-24) Can the be refactored away from any?
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reject: (reason?: any) => void,
    hub: ZeroConfService,
  ) {
    console.info('testForHub() testing for hub to show up on the network', hub);
    const networkInfo = await fetchNetworkInterfaces(hub);
    console.info('Setup - testForHub() - network info', networkInfo);

    // Check if the data exists for both interface and dns
    if (networkInfo) {
      resolve(networkInfo);
    } else {
      // If data is not present, call didHubConnect
      didHubConnect(resolve, reject, hub);
    }
  }

  function clearConnectionTimeout() {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }

  async function sendWiFiCredentialsToHub(
    ssid: string,
    password: string,
    hub: ZeroConfService,
  ) {
    // Lets make sure everything is cleaned up before starting
    console.info('Setup - sendWiFiCredentialsToHub() -  store', ssid, password);
    attempts.value = 0;
    clearConnectionTimeout();
    // post credentials to hub
    if (!hub) throw 'sendWiFiCredentialsToHub() - Hub is undefined';

    const url = getHubUrl(hub).url + '/hubs/wifi/add';
    const config = request({
      url,
      baseURL: getHubUrl(hub).url,
      instanceId: hub.txtRecord.hub_id,
      method: 'POST',
      data: { ssid, pass: password },
      fallbackToCloud: false,
    });

    return await config
      .execute()
      .then(async (response) => {
        if (!response) return;
        zeroConf.clearMockService();

        return response?.data?.value;
      })
      .catch((e) => {
        console.error('sendwificreds error', e);

        throw { message: 'Error sending WiFi credentials to system', error: e };
      });
  }

  async function fetchNetworkInterfaces(hub: ZeroConfService) {
    try {
      if (!hub) throw 'fetchNetworkInterfaces() - Hub is undefined';

      const hubBaseUrl = getHubUrl(hub).url;
      const url = hubBaseUrl + '/hubs/network/interfaces';
      const config = request({
        url,
        baseURL: hubBaseUrl,
        method: 'GET',
        instanceId: hub.txtRecord.hub_id,
        fallbackToCloud: false,
      });
      return await config.execute().then((response) => {
        console.info('Setup - fetchNetworkInterfaces() - response', response);
        if (response) return response.data.value;
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // Fetch the available WiFi networks that the hub can see
  async function fetchWiFiNetworks(hub?: ZeroConfService) {
    try {
      if (!hub) throw 'fetchWiFiNetworks() - Hub is undefined';

      // const url = 'https://192.168.11.1/hubs/wifi/scan_results';
      const url = getHubUrl(hub).url + '/hubs/wifi/scan_results';
      const config = request({
        url,
        baseURL: getHubUrl(hub).url,
        method: 'GET',
        instanceId: hub.txtRecord.hub_id,
        fallbackToCloud: false,
      });

      return await config.execute().then((response) => {
        if (response && Array.isArray(response.data.value)) {
          // Filter out duplicates by ssid
          const uniqueNetworks: Network[] = response.data.value.filter(
            (network: Network, index: number, self: Network[]) =>
              index === self.findIndex((n) => n.ssid === network.ssid),
          );

          // Assign the unique networks to networks.value
          networks.value = uniqueNetworks;

          return uniqueNetworks;
        }
        return [];
      });
    } catch (e) {
      console.error(e);
      networks.value = [];
      return [];
    }
  }

  function fetchConnectionError(
    hub: ZeroConfService,
  ): Promise<Record<string, string[]> | null> {
    try {
      const url = getHubUrl(hub).url + '/hubs/wifi/failed';
      const hubBaseUrl = getHubUrl(hub).url;

      const config = request({
        url,
        baseURL: hubBaseUrl,
        method: 'GET',
        instanceId: hub.txtRecord.hub_id,
        fallbackToCloud: false,
      });

      return config.execute().then((response) => {
        if (response) {
          return response.data.value;
        }
        return null;
      });
    } catch (e) {
      console.error('Setup - fetchConnectionError catch', e);
      throw e;
    }
  }

  async function fetchHubInfo(hub: ZeroConfService): Promise<HubInfo | null> {
    const url = getHubUrl(hub).url + '/info';
    const config = request({
      url,
      method: 'GET',
    });

    return await config.execute().then((response) => {
      if (response) {
        return response.data.value;
      }
      return null;
    });
  }

  function isLuminNetwork(ssid: string | null) {
    // If the ssid is null we know its not a lumin network
    if (ssid === null) return false;
    const luminNetworkPrefixes = ['Lumin-'];
    return luminNetworkPrefixes.some((prefix) => {
      if (ssid.includes(prefix)) {
        return true;
      }
    });
  }

  async function checkHubCapabilities(
    hub: ZeroConfService,
  ): Promise<Capabilities | null> {
    const url = getHubUrl(hub).url + '/hubs/capabilities';
    const config = request({
      url,
      method: 'GET',
    });

    return await config.execute().then((response) => {
      if (response) {
        return response.data.value;
      }
      return null;
    });
  }
  return {
    networks,
    attempts,
    $reset,
    getHubUrl,
    sendWiFiCredentialsToHub,
    clearConnectionTimeout,
    fetchWiFiNetworks,
    isLuminNetwork,
    fetchConnectionError,
    fetchHubInfo,
    fetchNetworkInterfaces,
    checkHubCapabilities,
  };
});
