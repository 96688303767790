<script setup lang="ts">
import { captureException } from '@sentry/vue';
import { Notify } from 'quasar';
import { onErrorCaptured, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

const instance = getCurrentInstance();
const router = useRouter();
onErrorCaptured((err, vm, info) => {
  console.error('LErrorBoundary - onErrorCaptured', err, info, vm);
  captureException(err);
  if ((err as Error)?.message) {
    const { message } = err as Error;
    if (message) Notify.create(message);
  }
});

if (instance?.appContext)
  instance.appContext.config.errorHandler = (err: unknown, vm, info) => {
    console.error('LErrorBoundary - errorHandler', err, info, vm);
    if ((err as Error)?.message) {
      const { message } = err as Error;
      if (message) Notify.create(message);
    }
  };
router.onError((err) => {
  // Handle the router error here
  console.error('LErrorBoundary - Router error:', err);
  if (err) Notify.create(err);
  if (!err) {
    router.push({ name: 'Root' });
  }
});

window.onerror = function (message, source, lineno, colno, error) {
  console.error(
    'LErrorBoundary - window.onerror:',
    message,
    source,
    lineno,
    colno,
    error,
  );
  if (message) Notify.create(message);
};
//Stop error resizeObserver
const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 20);
    super(callback);
  }
};
</script>
<template>
  <slot></slot>
</template>
