import { RouteRecordRaw } from 'vue-router';
import { devicesRoutes } from './devices.routes';

export const systemsRoutes: RouteRecordRaw[] = [
  {
    name: 'Home',
    path: 'systems/:system_id/home',
    component: () => import('pages/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Automations',
    path: 'systems/:system_id/automations',
    component: () => import('src/pages/automations/AutomationsPage'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'CustomControls',
    path: 'systems/:system_id/automations/custom-controls',
    component: () => import('src/pages/automations/CustomControls'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'EditAutomation',
    path: 'systems/:system_id/automations/custom-controls/:automation_id',
    component: () => import('src/pages/automations/EditAutomationPage'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'UtilityEvents',
    path: 'systems/:system_id/utility-events',
    component: () => import('src/pages/automations/UtilityEventsPage'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'ManageUtilityEvent',
    path: 'systems/:system_id/utility-events/:automation_id',
    component: () => import('src/pages/automations/ManageUtilityEventPage'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'Insights',
    path: 'systems/:system_id/insights',
    component: () => import('pages/Insights.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Events',
    path: 'systems/:system_id/events',
    component: () => import('pages/events/EventsPage.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'ElectrificationMode',
    path: 'systems/:system_id/electrification-mode/:automation_id',
    component: () =>
      import('pages/electrification/ElectrificationModePage.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'SystemSettings',
    path: 'settings/systems/:system_id',
    component: () => import('pages/settings/system/SystemSettings.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'EditSystem',
    path: 'settings/systems/:system_id/edit',
    component: () => import('pages/settings/system/EditSystem.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },

  {
    name: 'ManageUsers',
    path: 'settings/systems/:system_id/manage-users',
    component: () => import('pages/settings/ManageUsersPage'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'NetworkSettings',
    path: 'settings/systems/:system_id/manage-network',
    component: () => import('pages/settings/system/NetworkSettings.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'PrivacyPolicy',
    path: 'settings/privacy-policy',
    component: () => import('pages/LegalDocuments/PrivacyPolicy.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'ConsentDocuments',
    path: 'settings/consent-documents',
    component: () => import('pages/LegalDocuments/ConsentDocumentation.vue'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  ...devicesRoutes,
];
