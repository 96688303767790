// import { Network, ConnectionStatus } from '../../src-capacitor/node_modules/@capacitor/Network/dist/esm';
import { Network, ConnectionStatus } from '@capacitor/network';

// https://github.com/digaus/community-capacitor-wifi/issues/11#issuecomment-900960732 <-- README
import { Wifi } from '@luminsmart/capacitor-wifi';
import { Geolocation } from '@capacitor/geolocation/dist/esm';
import { useSettings } from './useSettings';
import { computed, ref } from 'vue';
import { captureException } from '@sentry/vue';

const connectedSsid = ref<string | null>(null);
const connectionStatus = ref<ConnectionStatus>();
export function useNetwork() {
  const settings = useSettings();

  // init the connection status if its not already set
  if (!connectionStatus.value) {
    getNetworkStatus();
  }

  const isWiFi = computed(
    () => connectionStatus?.value?.connectionType === 'wifi',
  );

  async function getNetworkStatus() {
    const status = await Network.getStatus();
    connectionStatus.value = status;
    return status;
  }

  async function fetchConnectedSsid() {
    try {
      const { ssid } = await Wifi.getSSID();
      if (ssid) {
        connectedSsid.value = ssid;
        return ssid;
      }
      return null;
    } catch (e) {
      connectedSsid.value = null;
      captureException(e);
      console.error(e);
      return null;
    }
  }

  async function connectToWiFi(
    ssid: string,
    password?: string,
    isHiddenSsid = false,
  ) {
    try {
      // Connect to WiFi
      await Wifi.connect({
        ssid,
        password,
        isHiddenSsid,
      });
    } catch (e) {
      console.error('error from useNetwork', e);
      captureException(e);
      return false;
    }

    connectedSsid.value = await fetchConnectedSsid();

    if (connectedSsid.value === ssid) {
      return true;
    } else {
      return false;
    }
  }

  async function connectPrefix(
    ssidPrefix: string,
    password: string,
    joinOnce: boolean,
  ) {
    // Connect to WiFi
    try {
      await Wifi.connectPrefix({
        ssid: ssidPrefix,
        password,
        joinOnce,
      });
      connectedSsid.value = await fetchConnectedSsid();
      // TODO: throw exception if not Lumin- or refactor
      return connectedSsid.value;
    } catch (e) {
      captureException(e);
      console.error('connectPrefix exception', e);
      return null;
    }
  }

  async function disconnectFromWiFi() {
    await Wifi.disconnect();
  }

  async function checkPermissions() {
    let status;
    try {
      status = await Geolocation.checkPermissions();
      const { location } = status;
      return location;
    } catch (e) {
      captureException(e);
      console.error('checkPermissions error', e);
      return 'denied';
    }
  }

  async function requestPermissions() {
    try {
      const status = await Geolocation.requestPermissions();
      const { location } = status;
      return location;
    } catch (e) {
      captureException(e);
      console.error(e);
      return 'denied';
    }
  }

  async function openSettings() {
    return await settings.openSettings();
  }

  return {
    isWiFi,
    connectedSsid,
    getNetworkStatus,
    fetchConnectedSsid,
    connectToWiFi,
    disconnectFromWiFi,
    connectPrefix,
    checkPermissions,
    requestPermissions,
    openSettings,
  };
}

Network.addListener('networkStatusChange', (status) => {
  connectionStatus.value = status;
  try {
    Geolocation.checkPermissions().then((permStatus) => {
      if (permStatus.location === 'granted') {
        Wifi.getSSID()
          .then(({ ssid }) => {
            connectedSsid.value = ssid;
          })
          .catch((e) => {
            console.error('Geolocation checkPermissions', e);
            connectedSsid.value = null;
          });
      }
    });
  } catch (e) {
    captureException(e);
    console.error('useNetwork: catch networkStatusChange', e);
  }
});
