/*
 * installations.ts is used to manage an instance of an installation
 */
import { computed, ref } from 'vue';
import { getTemplate } from './templates';
import { Installation, InstallationState, Step } from 'src/types/Installation';
import { Router } from 'vue-router';
import { recursiveSearch } from 'src/stores/util/treeSearch';

export function useInstallation(router: Router): Installation {
  const template = ref<Step[]>([]);
  const isActive = ref<boolean>(false);
  const state = ref<InstallationState>({} as InstallationState);

  const activeStep = computed(() => {
    const foundStep = template.value.find((step) => {
      return !step.isCompleted(state.value);
    });
    return foundStep ? foundStep : false;
  });

  const getProgress = computed(() => {
    if (activeStep.value) {
      const stepIndex = template.value.indexOf(activeStep.value);
      const totalSteps = template.value.length;
      return stepIndex / totalSteps;
    }
    return 1;
  });

  async function setup(
    state: InstallationState = {},
    templatePath = './installation.js',
  ) {
    updateState(state);
    template.value = await getTemplate(templatePath);
  }

  function start() {
    isActive.value = true;
    if (activeStep.value) {
      router.push(activeStep.value.route);
    } else {
      console.error('could not start no active step', activeStep.value);
    }
  }

  function exit() {
    isActive.value = false;
    // Go to the root of the app and let it figure out where to go
    router.push({ name: 'Root' });
  }

  function next() {
    // Note we generally set the state of a page before calling next step,
    // setting the state before we call next  will shift the activeStep automatically to the next step if the isCompleted functions state is met
    if (activeStep.value) {
      const shouldSkipNextStep = activeStep.value.isCompleted(state.value);
      if (shouldSkipNextStep) {
        next();
      } else {
        const route = activeStep.value.route;
        if (typeof route !== 'string' && route?.params?.system_id === null) {
          route.params.system_id = state.value.system?.id;
        }
        router.push(route);
      }
    } else {
      exit();
    }
  }

  function previous() {
    router.back();
  }

  function updateState(newState: InstallationState) {
    state.value = { ...state.value, ...newState };
  }

  // Search this installations for a state key and return its value
  function findInState<Type>(key: string): Type {
    const found = recursiveSearch(state.value, key);
    return found;
  }

  function setActive(value: boolean) {
    isActive.value = value;
  }

  return {
    template,
    isActive,
    activeStep,
    getProgress,
    state,
    setup,
    start,
    exit,
    next,
    previous,
    updateState,
    findInState,
    setActive,
  };
}
