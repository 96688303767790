<script setup lang="ts">
import { buttonElement, itemElement } from '@luminsmart/building-blocks';
import { useSystemsStore } from 'src/stores/systems';
import { ref } from 'vue';
const systemsStore = useSystemsStore();
const errors = ref();
async function deleteSystem(sysId: string) {
  if (confirm('Do you really want to delete this system?')) {
    try {
      await systemsStore.deleteSystem({ system_id: sysId });
    } catch (e) {
      errors.value = e;
    }
  }
}
</script>
<template>
  <itemElement
    v-for="sys in systemsStore.systems"
    :key="sys.id"
    :label="sys.name"
  >
    <template #side>
      <buttonElement class="btn-tertiary" @click="deleteSystem(sys.id)">
        Delete
      </buttonElement>
    </template>
  </itemElement>
</template>
