<script setup lang="ts">
import {
  buttonElement,
  dialogElement,
  inputElement,
  itemElement,
} from '@luminsmart/building-blocks';
import { useManageHubWiFi } from 'src/composables/useManageHubWiFi';
import { ref } from 'vue';

const {
  autoConnectToHubsWiFi,
  ssid,
  password,
  connectToWifi,
  disconnectFromWiFi,

  connectedSsid,
  ssidToSend,
  passwordToSend,
  sendWiFiCredentialsToHub,
  fetchHubsNetworks,
  hubsNetworks,

  fetchHubInterface,
  hubsInterfaces,
  services,

  connectionError,
} = useManageHubWiFi();

const showWiFiSetup = ref();
</script>
<template>
  <itemElement
    label="Connect hub to WiFi"
    caption="Step through connecting a hub to a WiFi network"
    @click="showWiFiSetup = true"
  >
  </itemElement>
  <dialogElement position="bottom" v-model:show="showWiFiSetup">
    <div class="q-gutter-sm">
      <itemElement class="grid" label="Hubs status">
        <div>
          <div>Hub errors: {{ connectionError }}</div>
          <div>Phone Connected Network: {{ connectedSsid }}</div>

          <div v-for="service in services" :key="service.name" class="grid">
            <buttonElement
              class="btn-primary"
              @click="fetchHubInterface(service)"
            >
              Fetch {{ service.name }} Interfaces
            </buttonElement>
            <div class="text-small">
              Interfaces: {{ hubsInterfaces[service.name] }}
            </div>
          </div>
        </div>
      </itemElement>

      <itemElement class="grid">
        <buttonElement class="btn-primary" @click="autoConnectToHubsWiFi">
          Auto Connect to hub
        </buttonElement>
        <div class="text-h1 text-center">or</div>
        <inputElement v-model="ssid" label="ssid" />
        <inputElement v-model="password" label="password" />
        <buttonElement
          @click="connectToWifi()"
          :unelevated="true"
          class="btn-tertiary"
          label="Connect to wifi"
        />

        <buttonElement
          @click="disconnectFromWiFi()"
          :unelevated="true"
          class="btn-tertiary"
          label="Disconnect from wifi"
        />
      </itemElement>

      <itemElement label="Connect Hub to Network" class="grid q-gutter-sm">
        <buttonElement class="btn-primary" @click="fetchHubsNetworks">
          Fetch Networks
        </buttonElement>
        <div>hubsNetworks: {{ hubsNetworks }}</div>

        <inputElement v-model="ssidToSend" label="ssid" />
        <inputElement v-model="passwordToSend" label="password" />
        <buttonElement class="btn-primary" @click="sendWiFiCredentialsToHub">
          Send WiFi Info
        </buttonElement>
      </itemElement>
    </div>
  </dialogElement>
</template>
