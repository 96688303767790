<script setup lang="ts">
import { itemElement } from '@luminsmart/building-blocks';
import { useLocalAuth } from 'src/composables/useLocalAuth';
import { useZeroConf } from 'src/composables/useZeroConf';
import { useHubStore } from 'src/stores/useHubStore';
import { ref } from 'vue';
import { computed, onMounted } from 'vue';
const zeroConf = useZeroConf();
const localAuth = useLocalAuth();
const hubStore = useHubStore();
const allHubsInfo = ref<Record<string, object>>({});

const localInfo = computed(() => {
  return zeroConf.servicesArray.value.map((service) => {
    const hubId = service.txtRecord.hub_id;
    const localAuthToken = localAuth.localAuthTokens.value[hubId];
    return {
      service: service,
      token: localAuthToken,
      info: allHubsInfo.value?.[hubId],
    };
  });
});

onMounted(() => {
  allHubsInfo.value = {};
  zeroConf.servicesArray.value.forEach(async (service) => {
    const hubInfo = await hubStore.fetchHubInfo(service);
    const hubCapabilities = await hubStore.checkHubCapabilities(service);

    const hubId: string = service.txtRecord.hub_id;
    if (hubInfo) allHubsInfo.value[hubId] = { ...hubInfo, ...hubCapabilities };
  });
});
</script>
<template>
  <itemElement
    v-for="data in localInfo"
    :label="data.service.name"
    :key="data.service.hostname"
  >
    <pre lang="json" class="text-caption">
    {{ data }}
    </pre>
  </itemElement>
</template>
