import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { api } from 'src/api';
import { System } from 'src/types/System';
import { mergeMultipleRecords, mergeRecord, removeRecord } from '../util';
import { useAnalyticsStore } from '../useAnalyticsStore';
import { ZeroConfService, useZeroConf } from 'src/composables/useZeroConf';
import { useInstallationsManager } from 'src/composables/useInstallationsManager';
import { useDevicesStore } from '../devices';
import { useHubStore } from '../useHubStore';
import { Component } from 'src/types/Component';
import { useAuth } from 'src/composables/useAuth';
import { useApi } from 'src/composables/api/useApi';
import { useSocketStore } from '../useSocketStore';
import { useAutomationsStore, useUtilityEventsStore } from '../automations';
import { useOrganizations } from '@luminsmart/building-blocks';
import { LoadingBar } from 'quasar';
import { $ResetPinia } from '../util/ResetPinia';
import localforage from 'localforage';
import { InstallationState } from 'src/types/Installation';

type access_role = 'owner' | 'user';
interface User {
  email: string;
  access_roles: access_role[];
  invite_accepted: boolean;
  id: string;
}

export const useSystemsStore = defineStore('systems', () => {
  const route = useRoute();
  const router = useRouter();
  const installationsManager = useInstallationsManager();
  const hubsStore = useHubStore();
  const zeroConf = useZeroConf();
  const { user, isAuthenticated, logout } = useAuth();
  const socketStore = useSocketStore();
  const utilityEvents = useUtilityEventsStore();
  const devicesStore = useDevicesStore();
  const orgState = useOrganizations();
  const automationsStore = useAutomationsStore();
  const { request } = useApi();
  // ================
  // State
  // ================
  const systems = ref<System[]>([]);
  const selected_system_id = ref<null | string>(null);
  const invitedUsers = ref<User[]>([]);
  const componentTypes = ref<Record<string, Component[]>>({});

  function $reset() {
    systems.value = [];
    selected_system_id.value = null;
    invitedUsers.value = [];
    componentTypes.value = {};
  }

  // ================
  // Getters
  // ================

  const getSelectedSystem = computed(() => {
    return getSystemById(getSelectedSystemId.value);
  });

  const getSystemById = (system_id: string) => {
    return systems.value.find((system) => system.id === system_id);
  };

  const getSelectedSystemId = computed(() => {
    let sys_id = '';
    if (route && route.params?.system_id) {
      sys_id = route.params?.system_id?.toString();
    } else if (
      selected_system_id.value &&
      getSystemById(selected_system_id.value)
    ) {
      sys_id = selected_system_id.value;
    } else if (systems.value.at(0)?.id) {
      const firstSystemId = systems.value?.at(0)?.id;
      if (firstSystemId) sys_id = firstSystemId;
    }
    return sys_id;
  });

  const getSystemGridStatus = computed(() => {
    // TODO: add logic here to get the systems status: off grid, online offline
    return 1;
  });

  //  get the ZeroConf service for this system if its available
  //  Note: You can use this to figure out if you can talk to a system / hub locally
  const getSystemsHubService = computed(() => {
    const systemId = getSelectedSystemId.value;
    if (!systemId.length) return;
    return zeroConf.getServiceBySystemId(systemId);
  });

  const getAdoptableHubs = computed(() => {
    const services = zeroConf.servicesArray.value;
    const adoptableHubs = services.filter((service) => {
      const isConfigured = systems.value.find(
        (system) => system.hub_id === service?.txtRecord.hub_id,
      );
      // TODO: figure out if the hub is in setup mode?
      return !isConfigured;
    });

    return adoptableHubs;
  });

  // Returns all zeroconf services (local hubs) that the user has authorization to
  const getConfiguredHubs = computed(() => {
    const services = zeroConf.servicesArray.value;
    const configuredHubs = services.filter((service) => {
      const isConfigured = systems.value.find(
        (system) => system.hub_id === service?.txtRecord.hub_id,
      );
      // TODO: figure out if the hub is in setup mode?
      return isConfigured;
    });
    return configuredHubs;
  });

  const getInvitedUsers = computed(() => invitedUsers.value);

  const getComponentTypes = computed(() => componentTypes.value);

  // ================
  // Actions
  // ================
  async function fetchSystems() {
    const url = '/systems';
    const config = request({
      url,
      method: 'GET',
      instanceId: 'cloud',
    });

    return config
      .execute()
      .then(async (response) => {
        if (!response) return;
        const data = response.data.value;
        // Check if we have a org if not fetch and see if we have any then.
        if (!orgState.selectedOrganization.value) {
          await orgState.fetchOrganizations();
        }
        if (orgState?.organizations?.value?.length) {
          // See if the system that is selected is already loaded in if its not lets fetch it from the org end point
          if (
            !data.find((sys: System) => sys.id === getSelectedSystemId.value)
          ) {
            const query = new URLSearchParams({
              system_id_filter: 'eq',
              system_id_values: getSelectedSystemId.value,
            });
            await orgState.fetchOrganizationSystems(query);
            orgState.systems.value.map((sys: System) => {
              sys.org_id = orgState.selectedOrganization.value.id;
              data.push(sys);
            });
          }
        }
        systems.value = mergeMultipleRecords(systems.value, data);
      })
      .finally(async () => {
        // Setup the installationsManager for all systems we have.
        await setupInstallations(systems.value);
        if (systems.value.length > 0) {
          let selectedSystemId = getSelectedSystemId.value;
          if (!selectedSystemId || selectedSystemId.length === 0) {
            selectedSystemId = systems.value[0].id;
          }
          await fetchSystem(selectedSystemId);
        }

        // If there are no systems in our store, create and start a new installation
        const isThereActiveInstallAlready =
          installationsManager.getActiveInstallation.value;
        if (
          systems.value.length === 0 &&
          isAuthenticated.value &&
          !isThereActiveInstallAlready
        ) {
          // If they are trying to access a route that needs a system id
          // if ('system_id' in route.params) {
          //   setSelectedSystemId(null);
          const installation = await installationsManager.setupInstallation();
          if (installation) {
            installationsManager.deactivateAll();
            installation.start();
          }
          // }
        }
      });
  }

  async function fetchLocalSystems() {
    const hubServices = zeroConf.servicesArray.value;
    if (hubServices.length > 0)
      // Loop over each hub and fetch its systems
      for (const hubService of hubServices) {
        try {
          const { url } = hubsStore.getHubUrl(hubService);
          const response = await api({
            url: 'systems',
            baseURL: url.toString(),
          });

          // This will only add new systems / unique systems and avoid duplicates that might come from the local api
          const combinedSystems = (systems.value = [
            ...systems.value,
            ...response.data,
          ]);
          systems.value = combinedSystems.filter((obj, index) => {
            return index === combinedSystems.findIndex((o) => obj.id === o.id);
          });
        } catch (err) {
          console.error(err);
        }
      }
    setupInstallations(systems.value);
  }

  async function fetchSystem(systemId: string): Promise<System | null> {
    LoadingBar.start();
    setSelectedSystemId(systemId);
    const url = `/systems/${systemId}`;
    const config = request({
      url,
      method: 'GET',
    });

    const system = await config
      .execute()
      .then(async (response) => {
        if (response) {
          const data = response?.data.value;
          const wasSystemAccessedViaOrg = orgState.systems.value.find(
            (sys: System) => sys.id === systemId,
          );
          if (wasSystemAccessedViaOrg) {
            data.org_id = wasSystemAccessedViaOrg.org_id;
          }
          systems.value = mergeRecord(systems.value, data);

          // Update the installation with the full system so we can tell if we need to do more configuration
          const installation = installationsManager.searchForInstallation(
            'id',
            response?.data.value.id,
          );

          if (installation) {
            let installationConfig: InstallationState = {
              system: response?.data.value,
              hardware: response?.data?.value.hub_type,
            };
            if (data.electrification_mode === true) {
              installationConfig = {
                ...installationConfig,
                electrification_enabled: true,
                'overload-control': true,
              };
              // Does the system have a fully configured overload control
              const hasOverloadControl = automationsStore.automations?.find(
                (automation) =>
                  automation.type == 'overload_control' &&
                  automation.system_id === data.id,
              );
              if (hasOverloadControl) {
                const overloadProtect = hasOverloadControl.actions.find(
                  (a) => a.type === 'overload_protect',
                );
                if (overloadProtect) {
                  const controlledDevices = devicesStore.getDevices.filter(
                    (d) =>
                      overloadProtect.devices.find((dev) => dev.id === d.id),
                  );

                  installationConfig = {
                    ...installationConfig,
                    breakerRating: overloadProtect.data.service_limit,
                    amperageLimit: overloadProtect.data.setpoint,
                    controlledDevices: controlledDevices,
                  };
                }
              }
            }

            installation.updateState(installationConfig);
          }
          return getSystemById(response?.data.value.id) as System;
        }
        return null;
      })
      .catch((e) => {
        console.error('[useSystemStore() - fetchSystem error', e);
        // see if we get a 404 error from the api only then should we remove the selected system id
        // AKA the system was removed and the user no longer has access to it
        if (e?.status === 404) {
          setSelectedSystemId(null);

          systems.value = systems.value.filter((sys) => sys.id !== systemId);
        }
        return null;
      });
    // Fetch other info for the system we just got
    if (system) {
      socketStore.connectToSocket(systemId);
      await devicesStore.fetchDevices(systemId);
      utilityEvents.fetchUtilityEvents(systemId);
      // We need to await the automations because the installation needs to decide if its completed or not
      await automationsStore.fetchAutomations(systemId);
      await setupInstallations(systems.value);
      fetchComponents();
    }
    LoadingBar.stop();

    return system;
  }

  async function deleteSystem({ system_id }: { system_id: System['id'] }) {
    const url = `/systems/${system_id}`;
    const config = request({
      url,
      method: 'DELETE',
    });

    return await config.execute().then((response) => {
      if (response) {
        systems.value = removeRecord(systems.value, response.data.value);
        setSelectedSystemId(null);
      }

      const analyticsStore = useAnalyticsStore();
      analyticsStore.trackEvent('delete-system', {});

      return response?.data.value as System;
    });
  }

  async function createSystem(
    hub: ZeroConfService,
    body: Record<string, unknown>,
  ): Promise<System | null> {
    const analyticsStore = useAnalyticsStore();
    const url = hubsStore.getHubUrl(hub).url + '/systems';
    analyticsStore.trackEvent('create-system', {
      hub,
      requestInfo: url,
      method: 'POST',
      data: body,
      instanceId: hub.txtRecord.hub_id,
    });
    const config = request({
      url,
      baseURL: hubsStore.getHubUrl(hub).url,
      method: 'POST',
      data: body,
      instanceId: hub.txtRecord.hub_id,
      fallbackToCloud: false,
    });

    return await config
      .execute()
      .then((response) => {
        if (response) {
          systems.value.push(response.data.value);
          setSelectedSystemId(response.data.value.id);
          return response.data.value as System;
        } else {
          analyticsStore.trackEvent('create-system-no-response', { response });
          return null;
        }
      })
      .catch((error) => {
        console.error('Error creating system', error);
        analyticsStore.trackEvent('create-system-catch', { error });
        throw error.response.data;
      });
  }

  async function updateSystem(system_id: string, params: Partial<System>) {
    const url = `/systems/${system_id}`;
    const config = request({
      url,
      method: 'PATCH',
      data: params,
    });

    return await config.execute().then((response) => {
      if (!response) return;
      systems.value = mergeRecord(systems.value, response.data.value);

      const analyticsStore = useAnalyticsStore();
      analyticsStore.trackEvent('update-system', {});
      return getSystemById(system_id);
    });
  }

  function setSelectedSystemId(system_id: string | null) {
    // IF we have a system id and we have a system object loaded we can set it.
    if (system_id && getSystemById(system_id)) {
      selected_system_id.value = system_id;
    } else {
      selected_system_id.value = null;
    }
  }

  // Test URL to see if the request is successful
  async function testApiEndpoint(
    url: string,
    options = {},
  ): Promise<boolean | null> {
    return await api
      .head(url, options)
      .then(() => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  // Helper function for setting up the installation workflow
  async function setupInstallations(_systems?: System[]) {
    if (!_systems || _systems.length === 0) return;
    for (const system of _systems) {
      // After we load in the systems check and see if we have an installation for them else create a new one
      const installation = installationsManager.searchForInstallation(
        'id',
        system.id,
      );
      if (!installation) {
        const installationConfig: InstallationState = {
          system: system,
          devices: devicesStore.getDevices,
          hardware: system.hub_type,
        };

        await installationsManager.setupInstallation(installationConfig);
      }
    }
  }

  async function getSystemsUsers(systemId: string) {
    invitedUsers.value = [];

    const url = `/auth/systems/${systemId}/users`;
    const config = request({
      url,
      method: 'GET',
      instanceId: 'cloud',
    });

    return config.execute().then((response) => {
      if (!response) return;
      invitedUsers.value = response.data.value.users;

      const analyticsStore = useAnalyticsStore();
      analyticsStore.trackEvent('get-systems-users', {});
    });
  }

  async function removeUserFromSystem(systemId: string, idToRemove: string) {
    const url = `/auth/systems/${systemId}/users/${idToRemove}`;
    const config = request({
      url,
      method: 'DELETE',
      instanceId: 'cloud',
    });

    return await config.execute().then((response) => {
      if (!response) return;

      // Remove the user from the ui
      const users = invitedUsers.value.filter((user) => user.id !== idToRemove);
      if (users) {
        invitedUsers.value = users;
      } else {
        invitedUsers.value = [];
      }
      // If the user removed self
      if (idToRemove === user.value?.sub) {
        const firstSystemId = systems.value?.[0].id;
        setSelectedSystemId(firstSystemId);
      }

      const analyticsStore = useAnalyticsStore();
      analyticsStore.trackEvent('remove-user-from-systems', {});
    });
  }

  async function addUserToSystem(
    systemId: string,
    email: string,
    access_roles: access_role[],
  ) {
    const analyticsStore = useAnalyticsStore();
    const url = `/auth/systems/${systemId}/users`;
    const config = request({
      url,
      method: 'PUT',
      data: {
        email,
        access_roles,
      },
      instanceId: 'cloud',
    });

    return await config.execute().then((response) => {
      if (!response) return false;
      if (!invitedUsers.value) {
        invitedUsers.value = [];
      }

      invitedUsers.value.push(response.data.value);
      analyticsStore.trackEvent('add-user-to-systems-success', {});
      return true;
    });
  }

  async function fetchComponents() {
    const url = `/systems/${getSelectedSystemId.value}/component-types`;
    const config = request({
      url,
      method: 'GET',
    });

    return await config.execute().then((response) => {
      if (!response) return;

      const sortedList: Record<string, Component[]> = {};
      Object.keys(response.data.value)
        .sort()
        .forEach(function (v) {
          sortedList[v] = response.data.value[v].sort(
            (a: Component, b: Component) => a.type.localeCompare(b.type),
          );

          sortedList[v] = sortedList[v].map((item) => {
            return {
              ...item,
              label: item.type,
              value: item.type,
            };
          });
        });

      componentTypes.value = sortedList;
    });
  }

  async function deleteAccount() {
    const analyticsStore = useAnalyticsStore();
    const url = `/auth/users/${user.value?.sub}`;
    const config = request({
      url,
      method: 'DELETE',
      instanceId: 'cloud',
    });
    analyticsStore.trackEvent('delete-account', {
      user: user.value,
      systems: systems.value,
    });

    return await config.execute().then(async (response) => {
      if (!response) return false;
      // Reset the apps state
      localStorage.clear();
      localforage.clear();
      $ResetPinia();
      await logout();
      router.push({ name: 'Root' });

      return true;
    });
  }

  return {
    // State
    selected_system_id,
    systems,
    $reset,
    // Getters
    getAdoptableHubs,
    getComponentTypes,
    getConfiguredHubs,
    getInvitedUsers,
    getSelectedSystem,
    getSelectedSystemId,
    getSystemById,
    getSystemGridStatus,
    getSystemsHubService,
    // Actions
    addUserToSystem,
    createSystem,
    deleteSystem,
    fetchComponents,
    fetchLocalSystems,
    fetchSystem,
    fetchSystems,
    getSystemsUsers,
    removeUserFromSystem,
    setSelectedSystemId,
    testApiEndpoint,
    updateSystem,
    deleteAccount,
  };
});
