import { z } from 'zod';

// Use plain javascript object to convert into a type
// later. Explanation about why here: https://www.youtube.com/watch?v=jjMbPt_H3RQ

export const DEVICE_TYPES = {
  EDGE_DEVICE_TYPE: 'edge_circuit',
  LSP_DEVICE_TYPE: 'lsp_switchable_circuit',
  LSP_NON_SWITCHABLE_TYPE: 'lsp_nonswitchable_circuit',
  LSP_GVD: 'gvd',
  STORAGE_TYPE: 'storage',
} as const;
const [firstKey, ...otherKeys] = Object.values(DEVICE_TYPES);
export const DeviceTypeSchema = z.enum([firstKey, ...otherKeys]);
