import { RouteRecordRaw } from 'vue-router';

export const setupRoutes: RouteRecordRaw[] = [
  {
    name: 'setup',
    path: '/setup',
    redirect: () => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { name: 'StartSetup' };
    },
    component: () => import('layouts/SetupLayout.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'StartSetup',
        path: 'start',
        component: () => import('pages/setup/Start'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'RequiredPermissions',
        path: 'required-permissions',
        component: () => import('pages/setup/RequiredPermissions'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },

      {
        name: 'ConnectToWiFi',
        path: 'connect-to-wifi',
        component: () => import('pages/setup/ConnectToWiFi'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'HardwarePreference',
        path: 'hardware-preference',
        component: () => import('pages/setup/HardwarePreference'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'ConnectionPreference',
        path: 'connection-preference',
        component: () => import('pages/setup/ConnectionPreference'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'ConnectToHub',
        path: 'connect-to-hub',
        component: () => import('src/pages/setup/WiFi/ConnectToHub'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'ConnectHubToNetwork',
        path: 'connect-hub-to-network',
        component: () => import('src/pages/setup/WiFi/ConnectHubToNetwork'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'WaitForHubToConnect',
        path: 'wait-for-hub-to-connect',
        component: () => import('src/pages/setup/WiFi/WaitForHubToConnect'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'DiscoverHubs',
        path: 'discover-hubs',
        component: () => import('pages/setup/DiscoverHubs'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'HubNotFound',
        path: 'system-not-found',
        component: () => import('src/pages/setup/WiFi/HubNotFound'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },

      {
        name: 'SetupComplete',
        path: 'setup-complete',
        component: () => import('src/pages/setup/SetupComplete'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },

      // Configuration
      {
        name: 'ConfigurationChecklist',
        path: 'configuration/:system_id/checklist',
        component: () => import('pages/setup/ConfigurationChecklist'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'ConfigureSystem',
        path: 'configuration/:system_id/edit',
        component: () => import('src/pages/settings/system/EditSystem.vue'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'ConfigureDevices',
        path: 'configuration/:system_id/devices/add',
        component: () => import('src/pages/devices/AddDevices'),
        meta: {
          requiresAuth: false,
          showBackBtn: true,
        },
      },
      {
        name: 'ConfigureDevice',
        path: 'configuration/:system_id/devices/:device_id/edit',
        component: () => import('src/pages/devices/EditDevice'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },

      {
        name: 'ElectrificationWorksheet',
        path: 'electrification-worksheet',
        component: () => import('src/pages/setup/ElectrificationWorksheet'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'PanelGuardBreakerRating',
        path: 'panel-guard-breaker-rating',
        component: () => import('src/pages/setup/PanelGuardBreakerRating'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'PanelGuardControlDevices',
        path: 'panel-guard-control-devices',
        component: () => import('src/pages/setup/PanelGuardControlDevices'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'PanelGuardLuminCT',
        path: 'panel-guard-lumin-ct',
        component: () => import('src/pages/setup/PanelGuardLuminCT'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'PanelGuardChooseCT',
        path: 'panel-guard-choose-ct',
        component: () => import('src/pages/setup/PanelGuardChooseCT'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'PanelGuardAmperageLimit',
        path: 'panel-guard-amperage-limit',
        component: () => import('src/pages/setup/PanelGuardAmperageLimit'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'PanelGuardReview',
        path: 'panel-guard-review',
        component: () => import('src/pages/setup/PanelGuardReview'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'ValidateUseCase',
        path: 'validate-use-case',
        component: () => import('src/pages/setup/ValidateUseCase'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
      {
        name: 'UseCaseIssue',
        path: 'use-case-issue',
        component: () => import('src/pages/setup/UseCaseIssue'),
        meta: {
          requiresAuth: true,
          showBackBtn: true,
        },
      },
    ],
  },
];
