<script lang="ts">
import { defineComponent, ref, watch, useSlots } from 'vue';
import type { QDialogProps } from 'quasar';
import LCloseButton from './LCloseButton.vue';
export default defineComponent({
  components: { LCloseButton },
  props: {
    label: {},
    /**
     * @model
     */
    show: { type: Boolean },
    persistent: { default: false },
    position: { type: String as () => QDialogProps['position'] },
    swipeable: { type: Boolean, default: false },
    maximized: { type: Boolean, default: false },

    alignAction: {
      type: String as () =>
        | 'right'
        | 'left'
        | 'center'
        | 'between'
        | 'around'
        | 'evenly'
        | 'stretch',
      default: 'right',
    },
  },

  setup(props, { emit }) {
    const showDialog = ref(props.show);

    watch(
      () => props.show,
      () => {
        showDialog.value = props.show;
      },
    );

    watch(showDialog, () => {
      if (showDialog.value == false) {
        emit('update:show', false);
      }
    });

    return {
      showDialog,
      slots: useSlots(),
      onSwipe(details: Record<string, unknown>) {
        // Bubbles swipe gesture to parent component (i.e. Bottom Sheet)
        // if `swipeable` prop is set to true
        if (!props.swipeable) return;
        emit('on:swipe', details);
      },
    };
  },
});
</script>
<template>
  <q-dialog
    v-model="showDialog"
    :persistent="persistent"
    :position="position"
    :maximized="maximized"
  >
    <q-card
      class="card card-shadow dialog-body"
      :class="{
        'safe-area-top': maximized,
        'q-pt-md': !swipeable,
      }"
      v-touch-swipe.mouse.vertical="onSwipe"
    >
      <!-- Header -->
      <div
        v-if="slots['header-text'] || swipeable"
        class="row full-width items-center relative-position"
      >
        <div class="text-h5">
          <slot name="header-text"></slot>
        </div>
        <q-space v-if="!swipeable" />
        <LCloseButton  v-if="!swipeable" v-close-popup :shouldGoBack="false" />
        <slot v-if="swipeable" name="sheet-header"></slot>
      </div>
      <LCloseButton
        v-else
        v-close-popup
        :shouldGoBack="false"
        class="fixed-top-right q-pr-sm"
      />

      <!-- Body -->
      <slot name="body"></slot>

      <!-- Actions -->
      <q-card-actions
        v-if="slots.actions"
        class="q-px-md q-pb-md q-gutter-y-sm"
        :align="alignAction"
      >
        <slot name="actions"></slot>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
