import { useNetwork } from 'src/composables/useNetwork';
import { useZeroConf, ZeroConfService } from 'src/composables/useZeroConf';
import { useHubStore } from 'src/stores/useHubStore';
import { computed, ref } from 'vue';
import { useCamera } from './useCamera';
import { NetworkInterfaces } from 'src/stores/useHubStore/useHubStore';
export function useManageHubWiFi() {
  const network = useNetwork();
  const hubStore = useHubStore();
  const zeroConf = useZeroConf();
  const camera = useCamera();

  const connectionError = ref();

  const isConnectedToLuminNetwork = computed(() => {
    return hubStore.isLuminNetwork(network.connectedSsid.value);
  });

  const connectedSsid = computed(() => {
    return network.connectedSsid.value;
  });

  const hubsNetworks = computed(() => {
    const availableNetworks = hubStore.networks?.map((network) => network.ssid);

    availableNetworks.push('Other');
    return availableNetworks;
  });

  const services = computed(() => {
    return zeroConf.servicesArray.value;
  });

  async function autoConnectToHubsWiFi() {
    await network.connectPrefix('Lumin-', 'luminpass', true);
  }

  async function scanBarcodeAndConnect() {
    try {
      const result = await camera.scanNative();
      if (result) {
        const rawValue = result.rawValue;
        let hubSsid = '';
        if (!rawValue.includes(',')) {
          const value = rawValue.trim().toUpperCase();
          hubSsid = `Lumin-${value}`;
        } else {
          const parts = rawValue.split(',');
          // Extract the third item and format it as needed
          const value = parts[2].trim().toUpperCase();
          hubSsid = `Lumin-${value}`;
        }
        await network.connectToWiFi(hubSsid, 'luminpass');
      }
    } catch (e) {
      console.error('[scanBarcodeAndConnect]', e);
    }
  }

  const ssid = ref('');
  const password = ref('');
  function connectToWifi() {
    if (ssid.value.length === 0) {
      alert('you need to enter a ssid');
      return;
    }
    network.connectToWiFi(ssid.value, password.value);
  }

  function disconnectFromWiFi() {
    network.disconnectFromWiFi();
  }

  async function verifyService() {
    const connectedSsid = network.connectedSsid.value;
    if (isConnectedToLuminNetwork.value && connectedSsid) {
      const hubSerial = connectedSsid.replace('Lumin-', '');
      zeroConf.createFallbackMockService(hubSerial);
      const service = zeroConf.getServiceBySerial(hubSerial);
      if (service) {
        // Check for previous attempts errors
        connectionError.value = await hubStore.fetchConnectionError(service);
        return service;
      } else {
        console.error(
          'No service found for',
          hubSerial,
          'found services',
          zeroConf.services.value,
        );
      }
    } else {
      console.error('Not connected to the lumin network', connectedSsid);
    }

    return false;
  }

  async function fetchHubsNetworks() {
    const service = await verifyService();
    if (service) {
      await hubStore.fetchWiFiNetworks(service);
    }
  }

  const ssidToSend = ref('');
  const passwordToSend = ref('');
  async function sendWiFiCredentialsToHub() {
    const service = await verifyService();
    if (service) {
      return await hubStore.sendWiFiCredentialsToHub(
        ssidToSend.value,
        passwordToSend.value,
        service,
      );
    }
    return false;
  }

  const hubsInterfaces = ref<Record<string, NetworkInterfaces>>({});
  async function fetchHubInterface(service: ZeroConfService) {
    hubsInterfaces.value[service.name] =
      await hubStore.fetchNetworkInterfaces(service);
  }

  return {
    scanBarcodeAndConnect,
    autoConnectToHubsWiFi,
    ssid,
    password,
    connectedSsid,
    connectToWifi,
    disconnectFromWiFi,
    isConnectedToLuminNetwork,
    ssidToSend,
    passwordToSend,
    sendWiFiCredentialsToHub,
    fetchHubsNetworks,
    hubsNetworks,

    fetchHubInterface,
    hubsInterfaces,
    services,

    connectionError,
  };
}
